@import "../../variables/variables.scss";
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  width: $fullWidth;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  position: fixed;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 50;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.background-color {
  display: flex;
  align-items: center;
  height: 250px;
  width: 300px;
  padding: 2.5rem 2rem;
  background-color: $mediumGrey;
  border-radius: 15px;
  box-shadow: $darkBoxShadow;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: $bodyFont;
  padding: 2rem;
  width: 250px;
  height: 200px;
  background: $whiteColor;
  border-radius: 15px;
  box-shadow: $boxShadow;
  border: 2px solid $darkAccent;

}

.heading {
  margin: 0;
  padding: 10px;
  color: $blackText;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.modal-content {
  font-style:italic;
  padding: 10px;
  font-size: 18px;
  color: $blackText;
  text-align: center;

}

.actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.top-close-btn {
  cursor: pointer;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: $whiteColor;
  background: $accent;
  transition: all 0.25s ease;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.9);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.top-close-btn:hover {
  transform: scale(110%);
}

.close-btn {
  font-family: $bodyFont;
  letter-spacing: 1.5px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  border: none;
  color: $primaryText;
  background: $accent;
  transition: all 0.25s ease;
}

.close-btn:hover {
  box-shadow: 0 10px 20px -10px $accent;
  transform: translateY(-5px);
  background: $darkAccent;
}
