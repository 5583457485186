@import "../../variables/variables.scss";

footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: $bodyFont;
  color: $primaryText;
  border-top: 1px solid rgba(255, 255, 255, 0.18);


  h4 {
    font-size: $h4Font;
    margin: 33px 0 16px;
  }

  p {
    font-family: $bodyFont;
    color: $primaryText;
    margin: 0;
    padding: 0.25rem;
  }

  .instagram {
    color: $accent;
    margin-top: $small;
    padding-bottom: $xsmall;
    height: 30px;
    width: 30px;
    transition: color 0.3s ease;

    &:hover {
      color: rgb(175, 7, 175);
    }
  }
}
