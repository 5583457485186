@import "../../../variables/variables.scss";

.contact-form {
  font-family: $bodyFont;
  font-size: $pFont;
  color: $primaryText;
  width: 65%;
  height: 100%;
  border-radius: $standard;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  h2 {
    text-align: center;
    color: $accent;
    font-family: $headerFont;
    font-size: $h2Font;
    padding-bottom: $small;
  }

  .hr-line {
    border-bottom: 1px solid $lightGrey;
    margin-bottom: 2rem;
    align-self: center;
  }

  form {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: $darkBoxShadow;
    border-radius: 15px 15px 0 0;
    padding: 3rem;
    margin: 0;
    color: $primaryText;
    display: flex;
    width: 65%;
    height: 85%;
    flex-direction: column;

    .name-input,
    .lastName-input,
    .email-input,
    .phone-input {
      width: 45%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
   

      input {
        font-family: $bodyFont;
        margin-top: $xsmall;
        background-color:$whiteColor;
        width: 100%;
        padding: 10px;
        border-radius:$standard;
        box-sizing: border-box;
        color: $blackText;
      }
    }

    .email-phone-container,
    .name-lastname-container {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1000px) {
        width: 100%;
        flex-direction: column;
      }
    }

    .select-input {
      width: 45%;
      select {
        background-color:$whiteColor;
        font-family: $bodyFont;
        margin-top: 0.5rem;
        width: 100%;
        padding: 10px;
        border-radius: $standard;
        box-sizing: border-box;
        background-color:$whiteColor;
      }
    }

    div {
      margin-bottom: 15px;
      label {
        font-weight: bold;
      }
    }

    .message-container {
      width: 100%;
      .regular-font {
        font-weight: 400;
      }
      textarea {
        margin-top: $xsmall;
        width: 100%;
        padding: 10px;
        border: 1px solid $whiteColor;
        border-radius: 5px;
        font-size: 16px;
        box-sizing: border-box;
        resize: vertical;
        height: 200px;
      }
    }

    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 35%;
        display: flex;
        justify-content: center;
        background-color: $accent;
        color: $primaryText;
        border: none;
        border-radius: $standard;
        padding: 10px 20px;
        font-size: 18px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: $darkAccent;
        }

        &:active {
          transform: translateY(2px);
        }
      }
    }
  }

  .error-message {
    color: red;
    background-color: $whiteColor;

  }

  .red-border {
    border-color: red;
    border-style: none;
    border: 2px solid red;
  }

  .center-modal {
    min-width: 100%;
    position: fixed;
  }
}
