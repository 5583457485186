@import "../../variables/variables.scss";

.main-container {
  background-color: $background;
  color: $primaryText;
  width: $fullWidth;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  

  @media screen and (max-width: 500px) {
    margin: 0 auto;
    width: 80% !important;
 
  }
  .logo-container {
    width: $fullWidth;
    min-height: $minH;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    

    img {
      width: 35%;
      height: 50%;

      @media screen and (max-width: 900px) {
        width: 90% !important;
      }
    }

    .brand-text {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $bodyFont;

      @media screen and (max-width: 900px) {
        width: 90%;
      }

      h1 {
        text-align: center;
        font-size: $h2Font;
        font-weight: 200;
        font-style: italic;
        margin-top: 4rem;
        margin-bottom: $small;

        .blue-color {
          color: $accent;
        }

        @media screen and (max-width: 900px) {
          font-size: 24px;
        }
      }

      h4 {
        text-align: center;
        margin-top: 0.5rem;
        font-size: $h4Font;
        width: 70%;
        color: $mediumGrey;

        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }

  .parallax-fixed-bg {
    width: 100%;
    height: 60vh;
    /* Important */
    position: relative;

    @media screen and (max-width: 500px) {
      width: 130%;
      height: 40vh;
    
     }
  }
  
  .parallax-wrap {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .parallax {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url(../../assets/weights.jpg);
    transform: translateZ(0);
    will-change: transform;
  }


  .process-container {
    text-align: center;
    font-family: $bodyFont;
    font-size: $h2Font;
    min-height: 70vh;
    width: $fullWidth;
    display: flex;
    margin-top: 4.5rem;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-top: 2.5rem;
      margin-bottom: $small;
    }

    h4 {
      margin-top: 0;
      margin-bottom: $xsmall;
      text-align: center;
      font-size: $h4Font;
      width: 70%;
      color: $mediumGrey;
      font-style: italic;
    }

    p {
      font-size: $pFont;
      margin: 0;
      margin-bottom: 2rem;
    }

    .process-info-container {
      display: flex;
      justify-content: center;
      margin: 5rem 0;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      width: 80%;
      background-color: $greyRGBA;
      box-shadow: 0 0 10px $accent;
      border-radius: $standard;


      @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width: 500px) {
       padding: 2rem 3rem;
        width: 80%;
      }
    }
  }

  .parallax-margin {
    margin-top: 5rem;
  }

  .parallax2 {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url(../../assets/bands.jpg);
    transform: translateZ(0);
    will-change: transform;
  }

    @media screen and (max-width: 500px) {
      width: 130%;
     }


  .location {
    width: $fullWidth;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $bodyFont;
    font-size: 35px;
    margin-top: 5rem;

    h2 {
      margin-top: 1.5rem;
      margin-bottom: $small;
    }

    .hr-line {
      margin-bottom: 7rem;
    }

    @media screen and (max-width: 500px) {
       width: 110%;
       text-align: center;
     }
  }

  .partners {
    width: $fullWidth;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: $bodyFont;
    margin-top: 3rem;
    // min-height: 70vh;
    // margin-top: 4.5rem;

    h2 {
      font-size: $h2Font;
      margin-top: 2.5rem;
      margin-bottom: $small;
    }

    p {
      text-align: center;
      margin-top: 0.5rem;
      font-size: $h4Font;
      width: 40%;
      color: $lightGrey;
      font-weight: bold;

      @media screen and (max-width: 500px) {
        font-size: 16px;
        width: 75%;
      }
    }
  }
}
