@import "../../../variables/variables.scss";

.testimonials {
  background-color: $greyRGBA;
  color: $blackText;
  width: $fullWidth;
  min-height: 55vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: $bodyFont;

  .testimonial-item-container {
    min-height: 100%;
    display: flex;
    position: relative;
    background-color: $whiteColor;
    box-shadow: $boxShadow;
    border-radius: $standard;
    flex-direction: column;
    align-items: center;
    width: $fullWidth;


    .testimonial-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      min-height: 100%;
      width: 100%;
      border-radius: 5px;
      transition: opacity 0.7s ease-in;
      opacity: 1;
    

      .content-wrapper {
        // position: absolute;
        // top: 0;
        min-height: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        h4 {
          font-size: 30px;
          padding-top: 2rem;
          padding-bottom: 0.5rem;
          margin: 0;
        }
      }
      .center-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 100%;
        p {
          width: 75%;
          font-size: 16px;
          line-height: 130%;

          @media screen and (max-width: 1250px) {
       font-size: 14px
         
          }

          @media screen and (min-width: 800px) and (max-width: 1200px) {
            /* Your styles */
            font-size: 13px;
        }
        }
      }
    }

    .testimonial-dots {
      position: absolute;
      bottom: 0;
      width: 30%;
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      z-index: 100;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $background;
        margin: 0 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }

      .dot.active {
        background-color: transparent;
        border: 2px solid $accent;
      }
    }
  }

  .responsive-buttons {
    display: none;
  }

  .testimonial-btn-container {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;

      .button-icon {
        font-size: 75px;
        color: $blackRGBA;
        transition: color 0.3s ease;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.2s ease all;
          color: $accent;
        }

        &:active {
          transform: translateY(2px);
        }
      }

      &:hover {
        cursor: pointer;
        border-radius: 5px;
        transform: scale(1.1);
        transition: transform 0.2s ease all;
      }

      button:active {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transform: translateY(2px);
      }
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .testimonials {
    display: flex;
    flex-direction: column;
    margin-bottom: 7rem;
    min-height: 25%;
  }
}


@media screen and (max-width: 1024px) {
  .testimonials .testimonial-item-container .testimonial-item .content-wrapper {
    position: relative;
  }
}

@media screen and (max-width: 1024px) {
  .testimonials .testimonial-item-container .testimonial-dots {
    position: relative;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .testimonials .testimonial-btn-container {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .testimonials .responsive-buttons {
    display: flex;
  }
}
