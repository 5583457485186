@import "../../variables/variables.scss";

.services-icons {
    height: 20%;
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
   

    .img-container {
      width: 25%;
      height: 25%;
      border-radius: 50%;
      background-color: rgba(17, 163, 213, 0.75);
      margin: 2px;
      display: flex;
      justify-content: center;
      box-shadow: inset 0 0 8px rgba(13, 130, 170,.75); 

      img {
        box-sizing: border-box;
        padding: 0.5rem;
        width: 70%;
        max-width: 70%;
        height: auto;
        margin: 1rem;
        box-shadow: none;
      }
    }
  }