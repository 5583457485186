@import "../../../variables/variables.scss";

.card-border {
  min-width: 32%;
  min-height: 100%;
  box-shadow: $boxShadow;
  background-color: $blackRGBA;
  border-radius: $standard;
  padding: 1rem;
  margin: 0.5rem;

  @media screen and (max-width: 950px) {
    width: 100% !important;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .process-info {
      background-color: $blackRGBA;
      display: flex;
      padding: 3px;
      box-shadow: 0 0 4px $blackRGBA;
      border: 3px solid rgba(17, 163, 213, 0.5);
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      .img-container {
        min-height: auto;
        width: 95%;
        margin-top: $small;
        border-radius: 5px;
        box-shadow: 0 0 4px $accent;

        @media screen and (max-width: 900px) {
          min-height: auto;
          width: 90%;
        }
      }

      h3 {
        color: $accent;
        padding-top: $small;
        margin-bottom: $small;
        font-style: italic;

        @media screen and (max-width: 500px) {
          font-size: 28px;
        }
      }

      .card-text {
        background-color: $lightGreyRGBA;
        box-shadow: 0 0 4px $accent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        border-radius: $standard;
        width: 95%;
        height: 100%;
        margin-top: 1rem;
        margin-bottom: 0.5rem;

        max-height: 500px;
        overflow: hidden;
        transition: max-height 0.3s ease;

        @media screen and (max-width: 950px) {
          display: none;

          &.open {
            display: flex;
            margin-top: 0;
            width: 90%;
          }
        }

        ul {
          font-size: $pFont;
          margin: 20px 0;
          width: 85%;
          padding-left: 2rem;
          list-style-position: initial;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          min-height: 150px;
          height: 200px;

          @media screen and (max-width: 950px) {
            padding-top: 1rem;
            min-height: 100px;
            height: 100px;
            padding-bottom: 1rem;
          }

          @media screen and (max-width: 550px) {
            padding-top: 1rem;
            min-height: 140px;
            padding-bottom: 1rem;
          }

          @media screen and (max-width: 450px) {
            padding-top: 1rem;
            min-height: 200px;
            padding-bottom: 1rem;
          }

          li {
            text-indent: 0;

            &:not(:first-child) {
              margin-top: 0.75rem;
            }
          }
        }

        p {
          box-sizing: border-box;
          font-size: 16px;
          font-style: italic;
          margin: 0;
          text-align: center;
          width: 90%;
          box-sizing: border-box;
          height: 40%;
          padding: 1rem 0 0 1rem;

          @media screen and (max-width: 950px) {
            margin-bottom: 1rem;
          }
        }
      }
      .accordion-indicator {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 0.5rem 0;

        @media screen and (min-width: 951px) {
          display: none;
        }

        svg {
          transition: transform 0.3s ease;
        }

        &.open {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .line {
    box-shadow: none;
    margin-top: none;
    max-width: 50%;
    margin-bottom: 1rem;
  }
}
