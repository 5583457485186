@import "../../variables/variables.scss";

.dropdown-menu {
  position: absolute;
  display: none;
  top: 90%;
  right: 0;
  margin-top: 3px;
  width: 200px;
  z-index: 40;

  .dropdown-list {
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    align-items: flex-end;
    justify-content: space-evenly;
    color: black;
    list-style: none;
    background-color: white;
    border: 5px solid $accent;
    border-radius: $standard;

    .dropdown-item {
      padding: 1rem 0 !important;
      border-bottom: 2px solid lightgray;
      width: 100%;
      text-align: right;

    }
    .dropdown-item:last-child {
      border-bottom: none; 
    }

    .dropdown-item a {
      color: $blackText !important;
      padding: 0 !important;
      margin: 0 !important;

      &:hover {
        color: $lightGrey !important;
      }
      
    }
  }
}

.services-link:hover .dropdown-menu {
  display: block;
}

.service-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(242, 242, 242);
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0;

  &:hover {
    color: $accent;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .caret {
    margin-left: 4px;
    transition: transform 0.3s ease;
  }
}

.services-link:hover .caret {
  transform: rotate(180deg);
}

.navbar {
  position: relative;
  width: $fullWidth;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $background;
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);

  .navbar-logo {
    display: flex;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 2rem;
    padding-top: 1.2rem;
    padding-bottom: 1rem;

    img {
      width: 75px;
      height: auto;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navbar-links {
    flex: 1;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0.5rem 0;
    padding: 0 2rem;

    .nav-link {
      cursor: pointer;
      padding-right: 3rem;

      a {
        text-decoration: none;
        color: $primaryText;
        font-family: $bodyFont;
        padding: 1rem 0;
        transition: color 0.3s ease, border-bottom 0.3s ease;
      }

      :hover {
        color: $accent;
        border-bottom: 1px solid $accent;
      }
    }

    .services-link {
      position: relative;
      a {
        text-decoration: none;
        color: $primaryText;
        font-family: $bodyFont;
        padding: 1rem 0;
        transition: color 0.3s ease, border-bottom 0.3s ease;
        &:hover {
          color: $accent;
        }
      }
    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}

//responsive navbar styles

.navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $accent;

  .open-nav {
    width: 70%;
    height: 70%;
    color: white;
  }

  @media screen and (min-width: 901px) {
    display: none;
  }
}
.navbar-menu-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  padding: 1rem;
  min-width: 85%;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: $lightGrey;
  z-index: 5;
  box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

  .resp-dropdown .caret.open {
    transform: rotate(180deg);
  }

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    z-index: 4;
  }

  .close-nav {
    width: 35px;
    height: 35px;
    color: $whiteColor;
    margin: 0.5rem 1.75rem;
    z-index: 10;
  }

  ul {
    font-family: $bodyFont;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    padding-inline-start: 0;

    .resp-link {
      margin: 1rem;
      padding-bottom: 1rem;
      position: relative;
      z-index: 7;

      a {
        color: white;
        text-decoration: none;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
      }

      .resp-dropdown {
        display: flex;
        justify-content: flex-end;
       

        .service-container {
          display: flex;
          align-items: center;
          
        }
      }

      .resp-dropdown-menu {
        margin-top: 1rem;
        background-color: $lightGreyRGBA;
        border-radius: $standard;
        padding: 1rem;
        width: 100%;
        

        .resp-dropdown-list {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;

        }

        .drop-link {
          margin: 0.5rem 0 0.5rem 0;

          a {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 901px) {
    display: none;
  }
}
