@import url('https://fonts.googleapis.com/css2?family=Mate+SC&family=Montserrat:ital,wght@0,400;1,300&family=Zilla+Slab&display=swap');
//fonts
$headerFont: "Mate SC", serif;
$bodyFont: "Montserrat", sans-serif;

//font sizes
$h1Font: 45px;
$h2Font: 35px;
$h3Font: 25px;
$h4Font: 20px;
$pFont: 18px; 
$smallpFont: 17px;  


//height and width: 
$minH: 90vh;
$fullWidth: 100%;

//text-colors:
$primaryText: rgb(242,242,242);
$blackText: rgba(40, 40, 40, 0.9);
$mediumGrey: rgb(99, 99, 99);

//colors
$background: rgb(0, 0, 0);
$mediumGrey: rgb(99, 99, 99);
$lightGrey: rgb(176, 176, 176);
$whiteColor: rgb(242, 242, 242);
$accent: rgb(17, 163, 213);
$darkAccent: rgb(13, 130, 170);

//background colors rgba
$greyRGBA: rgba(99, 99, 99, 0.95);
$mediumGreyRGBA: rgba(99, 99, 99, 0.45);
$lightGreyRGBA: rgba(176, 176, 176, 0.25);
$blackRGBA: rgba(0, 0, 0, 0.5);

//background-shadow
$insetShadow: inset 0 0 8px rgba(0, 0, 0, 0.5); 
$boxShadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
$darkBoxShadow: 0px 0px 10px rgba(0, 0, 0, 0.9);

//spacing (padding and margin):
$xsmall: 0.5rem;
$small: 1rem;
$medium: 2rem;

//border-radius
$standard: 5px;
