@import "../../variables/variables.scss";

.partner-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  margin-bottom: 2rem;
  height: 80%;
  width: 80%;

  @media screen and (max-width: 900px) {
    width: 95%;
  }
  .partner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;

    @media screen and (max-width: 1100px) {
      margin: 1rem 0;
      width: 25%;
    }

    @media screen and (max-width: 900px) {
      margin: 1rem 0;
      width: 40%;
    }

    @media screen and (max-width: 500px) {
      margin: 1rem 0;
      width: 100%;
    }
  }

  .partner-card {
    display: flex;
    border: 3px solid $mediumGrey;
    box-shadow: $insetShadow;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 0 0 auto;
    background-color: $whiteColor;
    padding: 0.5rem;
    margin: 1rem;
    height: 135px;
    width: 135px;
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    @media screen and (max-width: 900px) {
      height: 80px;
      width: 80px;
      margin: 0.5rem;
    }

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 70px $accent;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 90px;
        max-height: 90px;
        border-radius: 5px;
        margin-bottom: 5px;

        @media screen and (max-width: 900px) {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}

.partner-info {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-align: center;
  height: 180px;
  width: 100%;

  .partner-name {
    letter-spacing: 0.07rem;
    font-size: 14px;
    font-weight: bold;
    color: $accent;
    text-decoration: none;
    margin: 0px 5px 0px 5px;
    width: 90%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.1);
      color: $accent;
    }

    @media screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  .coupon-container {
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .code-text {
      display: flex;
      justify-content: center;
      margin: 5px 0 5px 0;
      p {
        width: 100%;
        font-size: 12px;
        padding: 0 5px 0 5px;
      }
    }

    .copied-button {
      color: $blackText;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      font-size: 14px;
      letter-spacing: 0.05rem;
      font-weight: bold;
      background: $whiteColor;
      padding: 0.65rem;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid $darkAccent;
    }

    button {
      background: $accent;
      font-weight: bold;
      letter-spacing: 0.1rem;
      color: $primaryText;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      padding: 0.65rem;
      margin-top: 1rem;
      font-family: $bodyFont;

      &:hover {
        background: $darkAccent;
      }
    }

    .coupon-code {
      color: $blackText;
      display: block;
      margin-top: 1rem;
      font-size: 14px;
      letter-spacing: 0.05rem;
      font-weight: bold;
      background: $whiteColor;
      padding: 0.65rem;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid $darkAccent;
    }
  }
}
