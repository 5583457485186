@import "./variables/variables.scss";

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: $background;

}

.blue-color {
  color: $accent !important;
}

//blue line beneath page headings
.hr-line {
  border: 1px solid $accent;
  margin-bottom: $small;
  width: 50%;
}

.grey-hr-line {
  border-bottom: 1px solid $lightGreyRGBA;
  margin-bottom: $small;
  width: 50%;
}

//controls for sliding through testimonials and image gallery
.controls {
  margin: 16px 0;
  display: flex;
  justify-content: center;

  button {
    margin: 0 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    .button-icon {
      font-size: 50px;
      color: $primaryText;
      transition: color 0.3s ease;

      &:hover {
        transform: scale(1.3);
        transition: transform 0.2s ease all;
        color: $accent;
      }

      &:active {
        transform: translateY(2px);
      }
    }
  }
}

//styles for contact link in nav and call button

.contact-link {
  color: $accent;
  text-decoration: none;
  font-family: $bodyFont;
  font-weight: bold;
  display: inline-block;
  padding: 0.75rem;
  border-radius: $standard;
  text-align: center;
  background-color: $whiteColor;
  transition: all 0.2s ease-in;

  &:hover {
    color: $whiteColor;
    background-color: $darkAccent;
  }
}