@import "../../variables/variables.scss";

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 60%;
  margin-bottom: 2rem;
  overflow: hidden;

  .image-list {
    display: flex;
    margin-right: 40px;
  }

  .image {
    flex: 0 0 20%;
    margin: 0 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    opacity: 0.35;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
    min-width: 700px;
  }

  .image:nth-child(3) {
    opacity: 1;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
    border: 2px solid #ddd;
  }

  .image:first-child {
    margin-left: 0;
  }

  .image:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 520px) {
    .image-gallery {
      width: 120%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100%;
    }

    .image-list {
      overflow-x: hidden;
      margin: 0;
    }

    .image {
      flex: 0 0 100%;
      border: none;
      opacity: 1;
      max-height: 300px;
      min-width: 100%;
    }
    img {
      object-fit: cover;
    }
  }
}
