@import "../../variables/variables.scss";
.faq {
  min-height: $minH;
  width: $fullWidth;
  display: flex;
  flex-direction: row;
  color: $primaryText;
  font-family: $bodyFont;
  margin-bottom: $medium;

  .faq-title {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;

    h1 {
      font-size: $h1Font;
      color: $primaryText;
      margin-bottom: 1rem;
    }
  }

  .faq-container {
    width: 40%;
    height: 100%;
    background-color: $mediumGreyRGBA;
    box-shadow: $darkBoxShadow;
    border-radius: $standard;

    .faq-text {
      max-width: 100%;
      padding: 2rem;

      h3 {
        font-size: $h3Font;
        font-family: $headerFont;
        margin-bottom: $medium;
        color: $accent;
      }
      p {
        font-size: $pFont;
      }
    }
  }
  .faq-questions-container {
    width: 45%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    @media screen and (max-width: 1024px) {
      display: none;
    }
    .faq-content {
      background-color: $whiteColor;
      border-radius: 5px 0 0 5px;
      position: sticky;
      max-height: 40%;
      display: flex;
      align-items: center;
      bottom: 6rem;
      right: 0;
      width: 50%;

      .icon-container {
        position: absolute;
        transform: translateX(-60%);
        background-color: $accent;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30%;
        width: 30%;
        border-radius: 50%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
      }

      p {
        padding: 2rem;
        padding-left: 3.5rem;
        color: $blackText;
        font-size: $pFont;
        text-align: center;
      }

      .bold {
        font-weight: bold;
      }

      a {
        color: $accent;
        text-decoration: none;
      }
    }
  }
}

.show {
  display: none;

  @media screen and (max-width: 950px) {
    display: block;
    position: fixed;
    bottom: 5%;
    right: 5%;
    background-color: $darkAccent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    min-width: 85px;
    border-radius: 50%;
    box-shadow: $darkBoxShadow;

    .icon {
      height: 50%;
      width: 50%;
    }
  }
}
.icon {
  box-sizing: border-box;
  color: $primaryText;
  width: 100%;
  height: 100%;
  margin: 1.5rem;
}

@media screen and (max-width: 950px) {
  .faq {
    flex-direction: column;
    width:  100%;
    justify-content: center;
    align-items: center;
  }

  .faq-title {
    min-width: 100%;
  }

  .faq-container {
    min-width: 90%;;
  }

  .faq-questions-container {
    display: none;
  }
}
