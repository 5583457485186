@import "../../variables/variables.scss";

.contact {
  background-color: transparent;
  position: relative;
  width: $fullWidth;
  min-height: $minH;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .image-container {
    position: absolute;
    background-image: url("../../assets/contact.jpg");
     /* Background image by Victor Frietas from Unsplash */
    min-height: 100%;
    width: 100%;
    background-size: cover;
    background-position: left;
    opacity: 0.5;
  }

  .contact-text {
    color: $primaryText;
    display: flex;
    align-items: flex-end;
    height: 75%;
    width: 35%;
    z-index: 2;
    margin-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 1rem;
    }

    .centered-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      box-shadow: $darkBoxShadow;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 0 15px 15px 0 ;
      text-align: center;
      font-style: italic;
      padding: 3rem;
      width: 100%;
      height: 30%;
      font-family: $bodyFont;
      font-size: $pFont;
      box-sizing: border-box;
      margin-bottom: 1rem;
    }

    .hr-line {
      border-bottom: 1px solid $lightGrey;
    }
  }
}
